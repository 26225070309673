export default [
    {
      header: 'Support',
    },
    {
      title: 'Faq',
      route: 'pages-faq',
      icon: 'FileTextIcon',
    },
    {
      title: 'Knowledge Base',
      route: 'pages-knowledge-base',
      icon: 'FileTextIcon',
    },
    {
      title: 'Tickets',
      route: 'apps-chat',
      icon: 'MessageSquareIcon',
    },
]
  