export default [
    {
      header: 'Apps',
    },
    {
      title: 'ERP & CRM',
      route: 'erp-crm',
      icon: 'ActivityIcon',
    },
    {
      title: 'Invoices',
      route: 'apps-invoice-list',
      icon: 'FileTextIcon',
    },
    {
      title: 'Projects',
      route: 'apps-todo',
      icon: 'CheckSquareIcon',
    },
    {
      title: 'Calendar',
      route: 'apps-calendar',
      icon: 'CalendarIcon',
    },
]
  